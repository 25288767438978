import React from 'react';
import Link from 'next/link';
import { useLazyQuery } from '@apollo/client';
import { GET_NOTIFICATION_COUNT } from '../graphql/queries';

interface NotificationIconProps {
  _id: string;
  hasBg?: boolean;
}

const FIVE_MIN_IN_MS = 300000;

export default function NotificationIcon({
  _id,
  hasBg = false,
}: NotificationIconProps) {
  const [getNotificationCount, { data }] = useLazyQuery(
    GET_NOTIFICATION_COUNT,
    {
      pollInterval: FIVE_MIN_IN_MS,
    }
  );
  React.useEffect(
    function fetchNotifications() {
      if (_id) {
        const query = async () => {
          getNotificationCount({
            variables: { filter: { user: _id, active: true } },
          });
        };
        query();
      }
    },
    [_id, getNotificationCount]
  );
  return (
    <div className="mr-2 flex relative">
      <Link href="/app/notifications" className="p-1 m-auto">
        {data && data?.notificationCount > 0 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-21 0 512 512"
            className={`w-5 h-5 ${
              hasBg ? 'text-gray-100' : 'text-primary-500'
            }`}
            fill="currentColor"
          >
            <path d="M213.332 512c-44.094 0-80-35.883-80-80 0-8.832 7.168-16 16-16s16 7.168 16 16c0 26.477 21.547 48 48 48s48-21.523 48-48c0-8.832 7.168-16 16-16s16 7.168 16 16c0 44.117-35.902 80-80 80zm0 0" />
            <path d="M389.332 448h-352C16.746 448 0 431.254 0 410.668a37.314 37.314 0 0113.055-28.375c.535-.469 1.11-.895 1.707-1.281C46.078 353.684 64 314.367 64 272.852v-59.52C64 130.988 131.008 64 213.332 64c3.414 0 7.105.062 10.52.64 8.722 1.45 14.613 9.708 13.16 18.41-1.45 8.704-9.856 14.595-18.41 13.141-1.707-.277-3.582-.191-5.27-.191C148.652 96 96 148.629 96 213.332v59.52c0 51.543-22.594 100.312-61.93 133.78-.32.259-.597.493-.941.727A5.28 5.28 0 0032 410.668c0 2.898 2.434 5.332 5.332 5.332h352c2.902 0 5.336-2.434 5.336-5.332 0-1.496-.555-2.582-1.152-3.309-.32-.234-.598-.468-.918-.726-39.36-33.492-61.93-82.238-61.93-133.781v-23.25c0-8.832 7.168-16 16-16s16 7.168 16 16v23.25c0 41.539 17.941 80.875 49.3 108.226.575.383 1.13.79 1.641 1.238a37.22 37.22 0 0113.059 28.352c0 20.586-16.746 37.332-37.336 37.332zm0 0" />
            <path d="M362.668 213.332C303.852 213.332 256 165.484 256 106.668S303.852 0 362.668 0c58.816 0 106.664 47.852 106.664 106.668 0 58.816-47.848 106.664-106.664 106.664zm0-181.332C321.492 32 288 65.492 288 106.668c0 41.172 33.492 74.664 74.668 74.664 41.172 0 74.664-33.492 74.664-74.664C437.332 65.492 403.84 32 362.668 32zm0 0" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={`w-5 h-5 ${hasBg ? 'text-gray-100' : 'text-gray-500'}`}
            fill="currentColor"
          >
            <path d="M450.201 407.453c-1.505-.977-12.832-8.912-24.174-32.917-20.829-44.082-25.201-106.18-25.201-150.511 0-.193-.004-.384-.011-.576-.227-58.589-35.31-109.095-85.514-131.756V57.036C315.301 25.586 289.757 0 258.359 0h-4.719c-31.398 0-56.942 25.586-56.942 57.036v34.655c-50.372 22.734-85.525 73.498-85.525 132.334 0 44.331-4.372 106.428-25.201 150.511-11.341 24.004-22.668 31.939-24.174 32.917-6.342 2.935-9.469 9.715-8.01 16.586 1.473 6.939 7.959 11.723 15.042 11.723h109.947C179.391 477.903 213.785 512 256 512s76.609-34.097 77.223-76.238H443.17c7.082 0 13.569-4.784 15.042-11.723 1.457-6.871-1.669-13.652-8.011-16.586zM226.699 57.036c0-14.881 12.086-26.987 26.942-26.987h4.719c14.856 0 26.942 12.106 26.942 26.987v24.917a144.658 144.658 0 00-29.306-2.987A144.695 144.695 0 00226.7 81.95V57.036zM256 481.951c-25.673 0-46.614-20.617-47.223-46.188h94.445c-.608 25.57-21.549 46.188-47.222 46.188zm60.4-76.239H103.015c2.595-4.044 5.236-8.623 7.861-13.798 20.104-39.643 30.298-96.129 30.298-167.889 0-63.417 51.509-115.01 114.821-115.01s114.821 51.593 114.821 115.06c0 .185.003.369.01.553.057 71.472 10.25 127.755 30.298 167.286 2.625 5.176 5.267 9.754 7.861 13.798z" />
          </svg>
        )}
      </Link>
    </div>
  );
}
