type TRoute = {
  href?: string;
  as?: string;
  icon?: React.ReactNode;
  text: string;
  isCollapsible?: boolean;
  privilege?: number;
  subLinks?: Array<{
    href: string;
    as: string;
    text: string;
  }>;
};

const routes: Array<TRoute> = [
  {
    text: 'Inicio',
    href: '/app',
    as: '/app',
    isCollapsible: false,
    privilege: 4,
    icon: (
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-5 h-5 mr-1"
      >
        <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
      </svg>
    ),
  },
  {
    text: 'Beneficios',
    href: '/app/benefits',
    as: '/app/benefits',
    isCollapsible: false,
    privilege: 2,
    icon: (
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-5 h-5 mr-1"
      >
        <path d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
      </svg>
    ),
  },
  {
    text: 'Solicitudes de Beneficios',
    href: '/app/requests',
    as: '/app/requests',
    isCollapsible: false,
    privilege: 4,
    icon: (
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-5 h-5 mr-1"
      >
        <path d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
      </svg>
    ),
  },
  {
    text: 'Reportes de quiebre',
    href: '/app/break-reports',
    as: '/app/break-reports',
    isCollapsible: false,
    privilege: 4,
    icon: (
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        className="w-5 h-5 mr-1"
      >
        <path d="M20 7v-.2c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h3.7m0-11H8m3.5 4H8m8-8H8m10 11v-5.5a1.5 1.5 0 0 1 3 0V18a3 3 0 1 1-6 0v-4" />
      </svg>
    ),
  },
  {
    text: 'Calculadora de Split',
    href: '/app/split-calculator',
    as: '/app/split-calculator',
    isCollapsible: false,
    privilege: 4,
    icon: (
      <svg
        className="w-5 h-5 mr-1"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
        />
      </svg>
    ),
  },
  {
    text: 'Cursos',
    href: '/app/quizzes',
    as: '/app/quizzes',
    isCollapsible: false,
    privilege: 1,
    icon: (
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-5 h-5 mr-1"
      >
        <path d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
      </svg>
    ),
  },
  {
    text: 'Usuarios',
    href: '/app/users',
    as: '/app/users',
    isCollapsible: false,
    privilege: 1,
    icon: (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="mr-1 w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
        />
      </svg>
    ),
  },
  {
    text: 'Clientes',
    href: '/app/clients',
    as: '/app/clients',
    isCollapsible: false,
    privilege: 4,
    icon: (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="mr-1 w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
  },
  {
    text: 'Carga de Datos',
    href: '/app/upload',
    as: '/app/upload',
    isCollapsible: false,
    privilege: 1,
    icon: (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="mr-1 w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
        />
      </svg>
    ),
  },
  {
    text: 'Descarga de Datos',
    href: '/app/download',
    as: '/app/download',
    isCollapsible: false,
    privilege: 3,
    icon: (
      <svg
        className="mr-1 w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
        />
      </svg>
    ),
  },
  {
    text: 'Puntos',
    href: '/app/transactions',
    as: '/app/transactions',
    isCollapsible: false,
    privilege: 2,
    icon: (
      <svg
        className="mr-1 w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
  },
  {
    text: 'Volumen',
    href: '/app/volumes',
    as: '/app/volumes',
    isCollapsible: false,
    privilege: 2,
    icon: (
      <svg
        className="w-5 h-5 mr-1"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
        />
      </svg>
    ),
  },
  {
    text: 'Inbox',
    href: '/app/inbox',
    as: '/app/inbox',
    isCollapsible: false,
    privilege: 3,
    icon: (
      <svg
        className="w-5 h-5 mr-1"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
        />
      </svg>
    ),
  },
  {
    text: 'Mantenimiento',
    isCollapsible: true,
    privilege: 1,
    icon: (
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-5 h-5 mr-1"
      >
        <path d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
      </svg>
    ),
    subLinks: [
      {
        text: 'Áreas',
        href: '/app/areas',
        as: '/app/areas',
      },
      {
        text: 'Categorías',
        href: '/app/categories',
        as: '/app/categories',
      },
      {
        text: 'Canales de Venta',
        href: '/app/sale-channels',
        as: '/app/sale-channels',
      },
      {
        text: 'Preguntas Frecuentes',
        href: '/app/faqs',
        as: '/app/faqs',
      },
      {
        text: 'Listas de Precios',
        href: '/app/price-lists',
        as: '/app/price-lists',
      },
      {
        text: 'Marcas',
        href: '/app/brands',
        as: '/app/brands',
      },
      {
        text: 'Segmento de Marca',
        href: '/app/brand-groups',
        as: '/app/brand-groups',
      },
      {
        text: 'Regiones',
        href: '/app/regions',
        as: '/app/regions',
      },
      {
        text: 'Rutas',
        href: '/app/routes',
        as: '/app/routes',
      },
      {
        text: 'Tipos de Tienda',
        href: '/app/store-types',
        as: '/app/store-types',
      },
      {
        text: 'Rutas TM',
        href: '/app/zones',
        as: '/app/zones',
      },
    ],
  },
];

export default routes;
